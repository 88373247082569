import request from '@/utils/request3.js'

// 查询EB claim policy
export function searchClaimablePolicies(data) {
  return request({
    url: '/claim/portal/searchClaimablePolicies',
    method: 'post',
    data: data
  })
}

export function getClaimList(query) {
  return request({
    url: '/claim/portal/getClaimLs',
    method: 'post',
    data: query
  })
}

export function getReportList(query) {
  return request({
    url: '/claim/portal/getReportLs',
    method: 'post',
    data: query
  })
}
