<template>
  <NavBar
    class="fu-v2-nav"
    :class="{fixed: fixed, 'fu-v2-nav-percent': percent}"
    :title="title"
    :left-arrow="arrow"
    @click-left="onClickLeft"
  >
    <template #title>
      <slot />
    </template>
  </NavBar>
</template>

<script>
import { isFunction } from '@fu/core'
import { NavBar } from 'vant'
import { dispatchAction } from '@/utils/dispatch'

export default {
  name: 'FuNavV2',
  components: { NavBar },
  props: {
    title: { type: String, default: '' },
    fixed: { type: Boolean, default: true },
    back: { type: [Boolean, String, Function], default: true },
    arrow: { type: Boolean, default: true },
    percent: { type: Boolean, default: false }
  },
  computed: {

  },
  methods: {
    onClickLeft() {
      // 自定义方法
      if (isFunction(this.back)) {
        this.back()
        return
      }

      // 自定义路由
      if (typeof this.back === 'string') {
        this.$router.push(this.back)
        return
      }

      // 没有历史记录则回退到原生页面
      if (window.history.length <= 1) {
        dispatchAction({
          method: 'backNativePage'
        })
        return
      }

      // 默认回退上一页
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss">
.fu-v2-nav-percent {
  width: 100%!important;
}
.fu-v2-nav {
  width: 100vw;
  height: 60px;
  align-items: center;
  background-color: #fff;
  color: #000;
  box-shadow: 0px 4px 3px 0px #0000000D;

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
  }

  &.van-nav-bar .van-icon {
    color: #000;
    font-size: 16px;
  }

  .van-nav-bar__content {
    justify-content: flex-start;
    height: 100%;
  }
  .van-nav-bar__title {
    margin: 0;
    margin-left: 40px;
    color: #000;
    font-size: 14px;
    font-weight: 600;
  }

  .van-nav-bar__left {
    left: 0;
  }
}
</style>
