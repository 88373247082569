<template>
  <div class="main-wrapper">
    <div class="claim-wrapper">
      <FuNav
        :fixed="false"
        :title="$t('ebClaim.TrackingClaim')"
        :arrow="true"
        :back="() => {$router.push({name: 'EbTrackClaim'})}"
        percent
      />
      <div class="claim-list-container">
        <Tabs v-model="active" @change="onChange">
          <Tab
            v-for="v in tabs"
            :key="v.name"
            :name="v.name"
            :title="v.none"
          >
            <div
              v-for="(item, index) in list"
              :key="`item${index}`"
              class="card-box"
            >
              <div class="card-order-info">
                <p class="card-order-info-flex"><span class="info-title">{{ active == 'processing' ? $t('ebClaim.RequestID') : $t('ebClaim.ClaimID') }}</span><span class="info-container">: {{ active == 'processing' ? item.reportUid : item.claimUid }}</span></p>
                <p class="card-order-info-flex"><span class="info-title">{{ $t('ebClaim.PolicyNo') }}</span><span class="info-container">: {{ item.policyNo }}</span></p>
              </div>
              <div class="card-status-info">
                <div class="insured-info">
                  <div>
                    <p class="name">{{ item.insuredName }}</p>
                    <p>{{ item.coverageName }}</p>
                  </div>
                  <img :src="item.insuranceLogo" alt="">
                </div>
                <div class="status-text" :style="{background: getBackground(active == 'processing' ? item.reportStatus : item.claimStatus), color: getColor(active == 'processing' ? item.reportStatus : item.claimStatus)}">
                  {{ active == 'processing' ? reportStatus[item.reportStatus] : claimStatus[item.claimStatus] }}
                </div>
                <div class="btn-group">
                  <Button
                    class="fu-v2-btn block"
                    round
                    block
                    type="danger"
                    @click="seeDetail(item)"
                  >{{ $t('ebClaim.SeeDetail') }}</Button>
                  <span />
                  <Button
                    v-if="(v.name == 'done' && item.claimStatus == 1 && item.applicationFormStatus != 2 && item.applicationFormStatus) || (v.name == 'processing' && item.reportStatus == 1 && item.applicationFormStatus != 2 && item.applicationFormStatus)"
                    class="fu-v2-btn danger block"
                    round
                    block
                    type="danger"
                    @click="jumpTo(item)"
                  >{{ $t('ebClaim.SubmitDocument') }}</Button>
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  </div>

</template>

<script>
import FuNav from '@/components/v2/FuNav'
import { Tab, Tabs, Button } from 'vant'
import img from '@/assets/imgs/portal0.png'
import { getClaimList, getReportList } from '@/api/eb.js'
import dao from '@/utils/dao'
import { toastLoading } from '@/utils'
import('@/views/claim/eb/css/reset.css')

export default {
  components: { Tab, Tabs, Button, FuNav },
  props: {},
  data() {
    return {
      loading: '',
      img,
      active: 'processing',
      tabs: [
        { name: 'processing', title: this.$t('ebClaim.Processing') }
        // { name: 'done', title: this.$t('ebClaim.Done') }
      ],
      claimStatus: ['', this.$t('ebClaim.Processing'), this.$t('ebClaim.Approved'), this.$t('ebClaim.Completed'), this.$t('ebClaim.Declined')],
      reportStatus: ['', this.$t('ebClaim.Processing'), this.$t('ebClaim.Approved'), this.$t('ebClaim.Declined'), this.$t('ebClaim.Cancelled')],
      list: [{}]
    }
  },
  computed: {
    getBackground() {
      return (status) => {
        const background = {
          processing: { 0: '#f5f5f5', 1: '#fff6e8', 2: '#EEFFF5', 3: '#FFF2F1', 4: '#FFF2F1' },
          done: { 0: '#f5f5f5', 1: '#fff6e8', 2: '#EEFFF5', 3: '#EEFFF5', 4: '#FFF2F1' }
        }
        return background[this.active][status]
      }
    },
    getColor() {
      return (status) => {
        const color = {
          processing: { 0: '#000000', 1: '#e28800', 2: '#08B752', 3: '#E74039', 4: '#E74039' },
          done: { 0: '#f5f5f5', 1: '#e28800', 2: '#08B752', 3: '#08B752', 4: '#E74039' }
        }
        return color[this.active][status]
      }
    }
  },
  activated() {
    // this.getClaimList()
    this.onChange()
  },
  methods: {
    onChange() {
      if (this.active == 'processing') {
        this.getReportList()
      } else {
        this.getClaimList()
      }
    },
    seeDetail(item) {
      if (item.claimUid) {
        this.$router.push({ name: 'EbTrackClaimDetail', query: {
          registerUid: item.claimUid
        }})
      } else {
        this.$router.push({ name: 'EbTrackReportDetail', query: {
          reportUid: item.reportUid
        }})
      }
    },
    async getClaimList() {
      this.loading = toastLoading()
      const { identityNo, phone } = this.$route.query
      const params = { identityNo, phone, pageNo: 0, pageSize: 1000 }
      const res = await getClaimList(params)
      this.list = res?.pageResult?.data // TODO: 删除
      const token = res.tmpVisitToken
      dao.set('tempToken', token)
      this.loading.clear()
      this.loading = null
    },
    async getReportList() {
      this.loading = toastLoading()
      const { identityNo, phone } = this.$route.query
      const params = { identityNo, phone, pageNo: 0, pageSize: 1000 }
      const res = await getReportList(params)
      this.list = res?.pageResult?.data // TODO: 删除
      const token = res.tmpVisitToken
      dao.set('tempToken', token)
      this.loading.clear()
      this.loading = null
    },
    jumpTo(item) {
      const obj = {}
      if (this.active == 'processing') {
        obj.reportUid = item.reportUid
        obj.reportPolicyUid = item.reportPolicyUid
      } else {
        obj.registerUid = item.claimUid
      }
      this.$router.push({ name: 'EbTrackForm', query: obj })
    }
  }
}
</script>
<style lang="scss" scoped>
.main-wrapper {
  height: 100vh;
  width: 100vw;
  background: #f5f5f5;
}
@media (min-width: 600px) {
  .claim-wrapper {
    width: 600px;
  }
}
.claim-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  background: #fff;
  height: 100vh;
  position: relative;
}
.claim-list-container {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  overflow-y: scroll;
}
::v-deep .van-tabs__line {
  width: 50%;
}
.btn-group {
  display: flex;
  gap: 10px;
}
.jump-btn {
  margin: 20px;
}
.card-box {
  margin: 10px;
  border-radius: 20px;
  border: 1px solid #f5f5f5;
  background-color: #fff;
  overflow: hidden;
  .card-order-info {
    background-color: #444655;
    color: #fff;
    .info-title {
      min-width: 70px;
    }
    .info-container {
      // text-align: right;
    }
    span {
      line-height: 16px;
      margin: 10px;
    }
  }
  .card-order-info-flex {
    display: flex;
  }
  .card-status-info {
    padding: 10px;
    .insured-info {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      img {
        width: 40px;
      }
      .name {
        font-size: 14px;
        font-family: 'Montserrat-Bold';
      }
    }
    .status-text {
      display: inline-block;
      padding: 5px;
      border-radius: 5px;
      // color: #e28800;
      // background-color: #fff6e8;
      margin: 10px 0;
    }
  }
}
::v-deep .van-tabs__nav--line {
  height: unset;
}
</style>
